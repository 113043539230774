import { TemplateElement } from '../../01-base/TemplateElement';
import styles from './ShortcutMenuElement.css';

class ShortcutMenuElement extends TemplateElement {
    constructor() {
        super(null, { autoRender: true, styles: [styles] });

        this._targets = [];
    }

    hooks() {
        return {
            connected: () => {
                this.getModules();
            },
        };
    }

    properties() {
        return {
            url: '',
            modules: [],
        };
    }

    template() {
        return `
            <div class="global-styles d-none ${
                this.checkModuleLength ? 'd-lg-flex' : 'd-lg-none'
            } area shortcut-menu-area position-fixed bg-primary">
                <div class="fixed-content d-flex">
                    <div class="text-wrapper position-relative text-uppercase text-white h-100">
                        <div class="text">
                            Schnellzugriff
                        </div>
                    </div>
                    <div class="d-flex justify-content-center flex-column p-3 ml-18">
                        <div class="arrow text-white">›</div>
                    </div>
                </div>

                <div ref="menuContent" class="menu-content">
<!--                    TODO: build <a> Tag from the data-->
                    ${this.modules
                        .map(module => {
                            return `<a class="content-item p-3 d-block" target="_blank" data-id="${module.label}" data-url="${module.url}" data-tracking-object="Shortcut" data-tracking-type="${module.label}">${module.label}</a>`;
                        })
                        .join('')}
                </div>

                <div class="close-arrow d-flex justify-content-center flex-column p-3">
                    <div class="arrow text-white">‹</div>
                </div>
            </div>
        `;
    }

    events() {
        return {
            '[data-url]': {
                click: ({ target }) => {
                    const targetAvailable = this._targets.find(item => item.id === target.getAttribute('data-id'));
                    if (window._etracker) {
                        window._etracker.sendEvent(
                            new et_ClickEvent(
                                target.getAttribute('data-tracking-object'),
                                target.getAttribute('data-tracking-type'),
                            ),
                        );
                    }
                    if (targetAvailable && targetAvailable.link.closed === false) {
                        targetAvailable.link.focus();
                    } else {
                        // if tab closed, remove target from _targets[];
                        const indexOfTargetAvailable = this._targets.indexOf(target.getAttribute('data-id'));
                        this._targets.splice(indexOfTargetAvailable, 1);

                        const link = window.open(target.getAttribute('data-url'), target.getAttribute('data-id'));
                        this.addLinkToTargets({ target }, link);
                    }
                },
            },
        };
    }

    computed() {
        return {
            checkModuleLength: () => {
                return this.modules.length > 0;
            },
        };
    }

    getModules() {
        fetch(this.url)
            .then(response => response.json())
            .then(data => (this.modules = data));
    }

    addLinkToTargets({ target }, link) {
        this._targets.push({
            id: target.getAttribute('data-id'),
            link,
        });
    }
}

customElements.define('shortcut-menu', ShortcutMenuElement);
